import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      renegocieSuaDivida: imageSharp(fluid: {originalName: { regex: "/hero-desenrola-negocie/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      facaSuaNegociacao: imageSharp(fluid: {originalName: { regex: "/faca-sua-negociacao/" }}) {
        fluid(maxWidth: 482, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      aproveiteCondicoes: imageSharp(fluid: {originalName: { regex: "/condicoes-especiais/" }}) {
        fluid(maxWidth: 456, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
