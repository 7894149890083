import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// Assets
import { Link, Button } from './style'

type heroProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
  sendDatalayerEvent: Function;
}

const AproveiteCondicoes = ({ setIsOpen, isMobile, oneLink, sendDatalayerEvent }: heroProps) => {
  const data = usePageQuery()

  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      element_action: 'click button',
      element_name: 'Negociação Amigável',
      section_name: 'Aproveite condições especiais com a Negociação Amigável do Inter!',
    })
  }

  return (
    <div className='py-5 bg-gray'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-md-last'>
            <Img fluid={data.aproveiteCondicoes.fluid} alt='Pessoa segurando celular com capinha laranja' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-3'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              <span className='d-lg-block'>Aproveite condições</span> <span className='d-lg-block'>especiais com a</span> <span className='d-lg-block'>Negociação Amigável do</span> Inter!
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500'>
              A Negociação Amigável é um jeito ainda mais simples e flexível de colocar a sua fatura atrasada em dia.
            </p>
            <div className='d-flex align-items-center mb-2'>
              <div><OrangeIcon size='MD' color='#FF7A00' icon='computer-call' /></div>
              <span className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 ml-2'>Você paga a partir de R$ 10 de entrada</span>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <div><OrangeIcon size='MD' color='#FF7A00' icon='inter-research' /></div>
              <span className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 ml-2'>Parcela o valor total em até 10x</span>
            </div>
            <div className='d-flex align-items-center'>
              <div><OrangeIcon size='MD' color='#FF7A00' icon='money-up' /></div>
              <span className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 ml-2'>Faz tudo direto pelo Super App</span>
            </div>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 mt-3 ml-2'>Conforme as parcelas são pagas, o limite do seu cartão de crédito Inter é liberado e você volta a usá-lo com tranquilidade.</p>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500 mt-3 ml-2'>A opção está disponível para clientes com atraso de até 60 dias da data de vencimento. Se for o seu caso, aproveite para negociar!</p>
            {
              isMobile ? (
                <Link
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      element_action: 'click button',
                      element_name: 'Negociação Amigável',
                      section_name: 'Aproveite condições especiais com a Negociação Amigável do Inter!',
                      redirect_url: oneLink,
                    })
                  }}
                  href={oneLink}
                >Negociação Amigável
                </Link>
              ) : (
                <Button
                  onClick={handleButtonClick}
                >Negociação Amigável
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </div>
 )
}

export default AproveiteCondicoes
