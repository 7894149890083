import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .card-advantages {
    min-height: 228px;

    @media ${device.tablet} {
      min-height: 287px;
    }
  }

  .react-multiple-carousel {
    &__arrow--right {
      right: 0;
    }
    &__arrow--left {
      left: -9px;
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;

  &:focus {
    outline: none;
  }

  @media ${device.desktopLG} {
    margin-top: 33px;
  }
  @media ${device.desktopXL} {
    margin-top: 27px;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media (min-width: ${device.desktopLG}) {
    margin-top: 33px;
  }
  @media (min-width: ${device.desktopXL}) {
    margin-top: 27px;
  }
` 
