import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .card-advantages {
    min-height: 232px;

    @media ${device.tablet} {
      min-height: 287px;
    }
  }

  .react-multiple-carousel {
    &__arrow--right {
      right: 0;
    }
    &__arrow--left {
      left: -9px;
    }
  }
`
